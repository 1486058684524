import React from 'react'

const Profile = () => {
  return (
    <div>
      Este es mi perfil
    </div>
  )
}

export default Profile
